const privacyPolicyArr = [
  {
    title: '商号',
    text:
`マッチングアプリアナライザー運営事務局`
  },
  {
    title: '所在地',
    text:
`福岡県福岡市中央区大手門3丁目14－27`
  },
  {
    title: '運営責任者',
    text:
`佐藤 明彦`
  },
  {
    title: 'お問い合せ',
    text:
`support@match-analyze.com`
  },
  {
    title: '設立',
    text:
`2023年11月10日`
  },
  {
    title: '事業内容',
    text:
`メディア運営`
  },
];

export default privacyPolicyArr;