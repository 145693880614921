import * as React from "react"
import styled from "styled-components";

import Layout from "@components/common/Layout"
import Seo from "@components/common/SEO"
import Article from "@components/policy/Article";

import companyArr from "@enums/company"

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo
        title={'会社概要 - マッチングアプリ アナライザー'}
      />
      <Container>
        <Body>
          {companyArr.map((elem, index)  => (
            <Article
              key={`article${index + 1}`}
              title={elem.title}
              text={elem.text}
            />
          ))}
        </Body>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
`;

const Body = styled.div`
  padding: 0px 35px 40px 35px;
`;


export default NotFoundPage
